<template>
  <jtn-ui-base-block-will-deprecate
    v-if="title"
    :header="title"
  >
    <template slot="content">
      <toggle>
        <lead-author-list
          slot="showedContent"
          :authors="shownAuthors"
        />
        <lead-author-list
          v-if="hiddenAuthors.length > 0"
          slot="hiddenContent"
          :authors="hiddenAuthors"
        />
      </toggle>
    </template>
  </jtn-ui-base-block-will-deprecate>
</template>

<script>
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { JtnUiBaseBlockWillDeprecate } from '@jtnews/jtn-ui';
import LeadAuthorList from './lead-author-list';
import Toggle from '../../../../shared/components/toggle';

const COUNT_OF_SHOWN_AUTHORS = 2;

export default {
  name: 'BroadcastLeadAuthor',
  components: {
    JtnUiBaseBlockWillDeprecate,
    LeadAuthorList,
    Toggle
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          data: [],
          params: {}
        };
      }
    }
  },
  data() {
    return {
      isShowMore: true
    };
  },
  computed: {
    authors() {
      return this.data.data.map(author => ({
        ...author,
        targetUrl: isAbsoluteUrl(author.url || '') ? '_blank' : '_self'
      }));
    },
    title() {
      return this.data.params.title;
    },
    shownAuthors() {
      return this.authors.filter((author, index) => index < COUNT_OF_SHOWN_AUTHORS);
    },
    hiddenAuthors() {
      return this.authors.filter((author, index) => index >= COUNT_OF_SHOWN_AUTHORS);
    }
  }
};
</script>
