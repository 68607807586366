<template>
  <div :class="$style['sticky-block-wrap']">
    <jtn-ui-base-block-will-deprecate
      :header="title"
      :url="hash"
      :has-icon="false"
      @headerClicked="setHash(hash)"
    >
      <div
        slot="content"
        ref="list"
        :class="[
          $style['scroll-block'],
          $style[hasScroll ? 'has-scroll' : ''],
          $style[isTop ? 'top' : ''],
          $style[isBottom ? 'bottom' : '']
        ]"
        :style="{ maxHeight: height > 0 ? `${height}px` : 'auto' }"
      >
        <slot />
      </div>
    </jtn-ui-base-block-will-deprecate>
  </div>
</template>

<script>
import { fromEvent, merge, Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';

import { JtnUiBaseBlockWillDeprecate } from '@jtnews/jtn-ui';

export default {
  name: 'StickyBaseBlock',
  components: {
    JtnUiBaseBlockWillDeprecate
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    pointSizes: {
      type: Object,
      default() {
        return null;
      }
    },
    hash: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      height: 0,
      destroy: new Subject(),
      hasScroll: false,
      isTop: false,
      isBottom: false
    };
  },
  computed: {
    scrollList() {
      return this.$refs.list;
    }
  },
  watch: {
    pointSizes(val) {
      this.setScrollByElement(val);
    }
  },
  mounted() {
    this.checkScroll();
    merge(fromEvent(window, 'scroll'), fromEvent(window, 'resize'))
      .pipe(throttleTime(500), takeUntil(this.destroy))
      .subscribe(() => {
        this.setSize();
      });

    merge(fromEvent(this.scrollList, 'scroll'), fromEvent(window, 'resize'))
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.checkScroll();
      });

    this.$nextTick(() => {
      this.setSize();
    });
  },
  beforeDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    setSize() {
      const padding = 20;
      const { top } = this.scrollList.getBoundingClientRect();

      if (top > 0) {
        this.height = window.innerHeight - top - padding;
      }
    },
    setHash(hash) {
      this.$router.push({ hash, query: this.$route.query }).catch(() => {
        // do nothing.
      });
    },
    setScrollByElement(pointSizes) {
      const { list } = this.$refs;
      const topMargin = 200;
      const linkTop = pointSizes.top;
      const linkHeight = pointSizes.height;

      if (
        linkTop - topMargin < list.scrollTop ||
        linkTop + linkHeight > this.height + list.scrollTop
      ) {
        list.scrollTop = linkTop - topMargin;
      }
    },
    checkScroll() {
      this.hasScroll =
        this.scrollList.clientHeight < this.scrollList.firstChild.clientHeight;
      this.isTop = this.scrollList.scrollTop === 0;
      this.isBottom =
        this.scrollList.clientHeight + this.scrollList.scrollTop >=
        this.scrollList.scrollHeight;
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.sticky-block-wrap {
  position: relative;
}

.scroll-block {
  overflow: auto;

  width: 100%;
  max-height: 100%;
  padding-right: 30px;
  box-sizing: border-box;

  &.has-scroll {
    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;

      width: 100%;
      height: 38px;

      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;

      width: 100%;
      height: 38px;

      background: linear-gradient(-180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);

      transform: rotate(180deg);
    }

    &.top {
      &:before {
        content: unset;
      }
    }

    &.bottom {
      &:after {
        content: unset;
      }
    }
  }

  @include on-res($desktop-tablet) {
    flex: 1 0 100%;
  }
}
</style>
