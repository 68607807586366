import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';
import { JtnUiTypography } from '../../jtn-ui-typography';

import styles from './jtn-ui-record-online-label.styles.scss?module';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiRecordOnlineLabel',
  components: {
    JtnUiTypography
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    isNewDesign: {
      type: Boolean,
      default: false
    },
    bgcolor: {
      type: String as () => 'green' | 'transparent',
      default: 'green'
    }
  },
  render() {
    return (
      <jtn-ui-typography
        class={[
          styles.label,
          styles[this.bgcolor],
          this.isNewDesign ? styles.newDesign : ''
        ]}
        tag="span"
        type="font-online-marker"
      >
        <span class={styles.dot} />
        <span domPropsInnerHTML={this.label} />
      </jtn-ui-typography>
    );
  }
});
