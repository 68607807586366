<template>
  <div :class="$style['wrap']">
    <slot name="showedContent" />
    <div v-if="hasHiddenContent" v-show="isShow" :class="$style['hidden-wrap']">
      <slot name="hiddenContent" :class="$style['hidden-content']" />
    </div>
    <div v-if="hasHiddenContent" :class="$style['show-more-btn']">
      <jtn-ui-typography
        :tag="'a'"
        :type="'font-theme-news'"
        :link-type="'link-blue'"
        @click.prevent="isShow = !isShow"
      >
        <span v-if="isShow">
          Свернуть
        </span>
        <span v-else>
          Показать всех
        </span>
      </jtn-ui-typography>
    </div>
  </div>
</template>

<script>
import { JtnUiTypography } from '@jtnews/jtn-ui';

export default {
  name: 'Toggle',
  components: {
    JtnUiTypography
  },
  props: {
    isShowByDefault: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: this.isShowByDefault
    };
  },
  computed: {
    hasHiddenContent() {
      return !!this.$slots.hiddenContent;
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.hidden-wrap {
  overflow: hidden;
}

.show-more-btn {
  display: none;

  margin-top: 16px;

  @include on-min-resize($laptop-tablet) {
    display: block;

    text-align: center;
  }
}
</style>
