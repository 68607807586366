











  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { AdvCreative } from '../adv-creative';
  import { AdvData } from '../adv-mobile-sticky-rsya/adv-mobile-sticky-rsya-body.component';

  type linkData = {
    adv: AdvData,
    zIndex: number
  };

  @Component({
    name: 'AdvTextLinks',
    components: {
      AdvCreative
    }
  })
  export default class AdvTextLinks extends Vue {
    @Prop({
      required: true,
      type: Array
    }) advData: AdvData[];

    get linksData (): linkData[] {
      return this.advData.map<linkData>((item, index, data) => {
        return {
          adv: item,
          zIndex: data.length - index
        };
      });
    }
}

