<template>
  <ul :class="$style.broadcastLeadsAuthors">
    <li v-for="author of authors" :key="author.id" :class="$style.author">
      <jtn-ui-avatar :class="$style.avatar" :size="48" type="base">
        <JtnUiImage
          v-if="showAvatar(author.image)"
          slot="photo"
          :src="author.image.data.img.src"
          :sources="author.image.data.sources"
          :loading="author.image.data.img.loading"
          :decoding="author.image.data.img.decoding"
          alt="Фото автора"
        />
      </jtn-ui-avatar>

      <div :class="$style.info">
        <jtn-ui-typography
          :class="$style.name"
          tag="h3"
          type="font-second-news-txt"
          link-type="link-blue"
        >
          <a
            v-if="author.url"
            :href="author.url"
            :title="author.name"
            :target="author.targetUrl"
            v-html="author.name"
          />
          <span v-else v-html="author.name" />
        </jtn-ui-typography>

        <jtn-ui-typography
          v-if="author.jobPosition"
          :class="$style.position"
          tag="span"
          type="font-photoday-type-1"
        >
          {{ author.jobPosition }}
        </jtn-ui-typography>
      </div>
    </li>
  </ul>
</template>

<script>
import { JtnUiTypography, JtnUiAvatar, JtnUiImage } from '@jtnews/jtn-ui';
import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';

import { StoreMixin } from '../../../../shared/mixins';
import generateImageUrl from '../../../../shared/utils/generateImageUrl';

const AVATAR_SIZE = 48;

export default {
  name: 'LeadAuthorsList',
  components: {
    JtnUiTypography,
    JtnUiAvatar,
    JtnUiImage
  },
  mixins: [StoreMixin],
  props: {
    authors: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    getAvatarUrl(img) {
      return generateImageUrl(img, true, AVATAR_SIZE, AVATAR_SIZE);
    },
    showAvatar(image) {
      return (
        guardUnspecified(image?.data?.img?.src) && guardEmptyString(image.data.img.src)
      );
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.broadcastLeadsAuthors {
  @include ul-clear();

  display: flex;
  flex-wrap: wrap;

  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.author {
  display: flex;
  flex-basis: 100%;

  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.avatar {
  flex-shrink: 0;

  margin-right: 12px;
}

.info {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.name {
  margin: 0 0 6px;

  @include on-res($mobile) {
    font-size: 13px;
  }
}

.position {
  display: block;

  font-size: 13px;
  line-height: 15px;

  @include on-res($mobile) {
    font-size: 12px;
    line-height: 26px;
  }
}
</style>
