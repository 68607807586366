import { isAbsoluteUrl } from '@portal/utils/util-url';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiRecordOnlineLabel } from '@jtn-ui/components/record';
import { JtnUiBaseBlock, JtnUiBtn, JtnUiSecondaryRecordItem } from '@jtnews/jtn-ui';
import {
  injectStylesMixin,
  tsStoreMixin,
  tsBaseBlockFunctionalityMixin,
  EntityDecoderMixin,
  RecordItemUtilsMixin
} from '@jtnews/shared';

import styles from './news-feed-block.styles.scss?module';

type BtnData = {
  text: string;
  url: string;
  target: '_self';
  dataTest: [[string, string]];
};

type Rubric = {
  id: number;
  name: string;
  path: string;
};

type Item = {
  viewType: string;
  id: number;
  header: string;
  subheader: string;
  commentsCount: number;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
  isActiveTranslation: boolean;
  hasMainPhotoCommercialLabel: boolean;
  hasVideoMark: boolean;
  viewsCount: number;
  rubrics: Rubric[];
  urls: {
    url: string;
    urlCanonical: string;
    urlComments: string;
  };
};

type Data = {
  params?: {
    title: string;
    link: string;
    button_text?: string; // eslint-disable-line @typescript-eslint/naming-convention
  };
  settings?: {
    place: string;
    subPlace: string;
  };
  firstNewsPlace?: number;
  data?: Item[];
};

type NewFormatReachGoal = {
  goalName: string;
  blockType: string;
  actionType: string;
  prop1?: string;
  prop2?: string;
};

const BLOCK_SIZE = 3;
const BLOCK_TYPE = 'Левая колонка';
export default componentFactoryOf()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .mixin(tsBaseBlockFunctionalityMixin)
  .mixin(EntityDecoderMixin)
  .mixin(RecordItemUtilsMixin)
  .create({
    name: 'NewsFeedBlock',
    props: {
      data: {
        type: Object as () => Data,
        default: null
      }
    },
    computed: {
      firstNewsPlace(): number {
        return this.data.firstNewsPlace || 1;
      },
      title(): string | undefined {
        return this.data?.params?.title;
      },
      link(): string | undefined {
        return this.data?.params?.link;
      },
      buttonText(): string | undefined {
        return this.data?.params?.button_text;
      },
      items(): Item[] {
        return this.data?.data || [];
      },
      hideBlock(): boolean {
        return (!this.items || this.items.length === 0) && !this.buttonText;
      },
      btnData(): BtnData | null {
        return this.buttonText
          ? {
              text: this.buttonText,
              url: this.link || '',
              target: '_self',
              dataTest: [['data-test', 'all_news_button_left_column']]
            }
          : null;
      }
    },
    methods: {
      getPlaceParams(index: number) {
        const currentPlace = this.firstNewsPlace + index;
        const firstArticlePlace =
          currentPlace - (currentPlace % BLOCK_SIZE || BLOCK_SIZE) + 1;
        const lastArticlePlace = firstArticlePlace + BLOCK_SIZE - 1;

        return {
          block: `${firstArticlePlace}-${lastArticlePlace}`,
          ids: this.items
            .slice(
              firstArticlePlace - this.firstNewsPlace,
              lastArticlePlace - this.firstNewsPlace + 1
            )
            .map(item => item.id)
            .join(',')
        };
      },
      getArticleTargetUrl(link: string) {
        return isAbsoluteUrl(link) ? '_blank' : '_self';
      },
      sendNewFormatReachGoal({
        actionType,
        goalName,
        prop1,
        prop2,
        blockType
      }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: `Блок ленты${blockType ? ` ${blockType}` : ''}`,
          actionType,
          goalName,
          prop1,
          prop2
        });
      },
      goToAllNews(reachGoalValue: string, direction: 'верх' | 'низ') {
        const fieldType = 'Лента новостей';

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          fieldType,
          reachGoalValue,
          'clickNewsFeed',
          true,
          BLOCK_TYPE
        );
        this.sendNewFormatReachGoal({
          actionType: 'Клик',
          blockType: `Блок ленты${BLOCK_TYPE ? ` ${BLOCK_TYPE}` : ''}`,
          goalName: 'ClickNewsline',
          prop1: `Все новости (${direction})`
        });
      },
      clickNews(currentIndex: number, recordId: number, isCommercial: boolean) {
        const currentPlace = this.firstNewsPlace + currentIndex;
        const articleType = isCommercial ? 'Рекламный' : 'Редакционный';

        const valueName = {
          [currentPlace]: {
            ['Переход на материал']: {
              [articleType]: recordId
            }
          }
        };

        const { block } = this.getPlaceParams(currentIndex);

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Лента новостей',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          valueName,
          'clickNewsFeed',
          true,
          BLOCK_TYPE
        );
        this.sendNewFormatReachGoal({
          blockType: block,
          actionType: 'Клик',
          goalName: `ClickNewsline${block}`,
          prop1: `${recordId}`
        });
      },
      clickRubric(
        { rubricName }: { rubricName: string },
        currentIndex: number,
        recordId: number,
        isCommercial: boolean
      ) {
        const currentPlace = this.firstNewsPlace + currentIndex;
        const articleType = isCommercial ? 'Рекламный' : 'Редакционный';

        const valueName = {
          [currentPlace]: {
            [`Переход на рубрику ${rubricName}`]: {
              [articleType]: recordId
            }
          }
        };

        const { block } = this.getPlaceParams(currentIndex);

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Лента новостей',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          valueName,
          'clickNewsFeed',
          true,
          BLOCK_TYPE
        );
        this.sendNewFormatReachGoal({
          blockType: block,
          actionType: 'Клик',
          goalName: `ClickNewsline${block}`,
          prop1: `${recordId}`,
          prop2: `Рубрика ${rubricName}`
        });
      },
      getObsHalfOptions(currentIndex: number, id: number, isCommercial: boolean) {
        return {
          callback: (isVisible: boolean) => {
            if (!isVisible) return;
            this.onVisibleBlock(currentIndex, id, isCommercial);
          },
          once: true,
          intersection: {
            threshold: 0.5
          }
        };
      },
      onVisibleBlock(currentIndex: number, id: number, isCommercial: boolean) {
        const currentPlace = this.firstNewsPlace + currentIndex;
        const articleType = isCommercial ? 'Рекламный' : 'Редакционный';
        const valueName = {
          [currentPlace]: {
            ['Просмотр']: {
              [articleType]: id
            }
          }
        };

        const { block, ids } = this.getPlaceParams(currentIndex);

        this.$_BaseBlockFuncMixin_sendNewReachGoal(
          'Лента новостей',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          valueName,
          'viewNewsFeed',
          true,
          BLOCK_TYPE
        );

        if (currentIndex % BLOCK_SIZE !== 0) {
          return;
        }

        this.sendNewFormatReachGoal({
          blockType: block,
          actionType: 'Просмотр',
          goalName: `ViewNewsline${block}`,
          prop1: ids
        });
      }
    },
    render() {
      return (
        <noindex>
          {!this.hideBlock && (
            <div class={styles.newsFeed}>
              <JtnUiBaseBlock
                header={this.title}
                url={this.link}
                hasBorder={false}
                dataTestHeader="all_news_left_block"
                onHeaderClicked={() => this.goToAllNews('Все новости (кнопка)', 'верх')}
              >
                <div class={styles.list}>
                  {this.items?.map((item: Item, index: number) => (
                    <JtnUiSecondaryRecordItem
                      v-observe-visibility={this.getObsHalfOptions(
                        index,
                        item.id,
                        item.isCommercial
                      )}
                      key={item.id}
                      url={item.urls.url}
                      target={this.getArticleTargetUrl(item.urls.url)}
                      header={this.entityDecode(item.header)}
                      oldDesign={false}
                      record-type="small"
                      data-vr-contentbox="Новость в левой колонке"
                      data-vr-contentbox-url={item.urls.urlCanonical}
                      class={styles.newsItem}
                      is-active-translation={item.isActiveTranslation}
                      has-commercial-label={item.hasMainPhotoCommercialLabel ?? false}
                      has-video-mark={item.hasVideoMark}
                      onLinkClicked={() =>
                        this.clickNews(index, item.id, item.isCommercial)
                      }
                      onRubricClicked={(event: { rubricName: string }) =>
                        this.clickRubric(event, index, item.id, item.isCommercial)
                      }
                    >
                      <JtnUiRecordOnlineLabel slot="onlineLabel" bgcolor="transparent" />
                    </JtnUiSecondaryRecordItem>
                  ))}
                </div>
                {this.btnData && (
                  <JtnUiBtn
                    class={styles.button}
                    href={this.btnData.url}
                    target={this.btnData.target}
                    dataAttrs={this.btnData.dataTest}
                    onClick={() => this.goToAllNews('Все новости (кнопка)', 'низ')}
                  >
                    {this.btnData.text}
                  </JtnUiBtn>
                )}
              </JtnUiBaseBlock>
            </div>
          )}
        </noindex>
      );
    }
  });
