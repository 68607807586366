import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';
import { AdvLabel } from '../../jtn-ui-image';

import styles from './jtn-ui-secondary-record-item.styles.scss?module';

interface Events {
  onRubricClicked: {
    event: Event;
    url: string;
    rubricName: string;
  };
  onLinkClicked: Event;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiSecondaryRecordItem',
    props: {
      header: {
        type: String,
        default: ''
      },
      subheader: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      target: {
        type: String as () => '_self' | '_blank',
        default: '_self'
      },
      recordType: {
        type: String as () => 'big' | 'small',
        default: 'small'
      },
      isActiveTranslation: {
        type: Boolean,
        default: false
      },
      isNewRecordDesign: {
        type: Boolean,
        default: false
      },
      hasVideoMark: {
        type: Boolean,
        default: false
      },
      oldDesign: {
        type: Boolean,
        default: true
      },
      hasCommercialLabel: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isVideoMark(): boolean {
        return !this.isActiveTranslation && this.hasVideoMark;
      },
      videoMarkSize(): number {
        const OLD_DESIGN_SIZE = 20;
        const NEW_DESIGN_SIZE = 16;
        return this.oldDesign ? OLD_DESIGN_SIZE : NEW_DESIGN_SIZE;
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs,
        on: this.$listeners
      };

      return (
        <article
          class={[
            styles.secondaryRecordItem,
            styles[this.recordType],
            this.isNewRecordDesign ? styles.newDesign : ''
          ]}
          {...attributes}
        >
          <div
            class={[this.subheader.length > 0 ? styles.header : styles.text]}
            data-vr-headline
          >
            {this.isVideoMark && (
              <span class={styles.markVideo}>
                <svg width={this.videoMarkSize} height={this.videoMarkSize}>
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-video-filled"></use>
                </svg>
              </span>
            )}

            {this.isActiveTranslation && (
              <span class={styles.onlineLabel}>{this.$slots.onlineLabel}</span>
            )}

            <a
              class={styles.link}
              href={this.url}
              title={this.header}
              target={this.target}
              onClick={(event: Event) => {
                this.$emit('linkClicked', event);
              }}
            >
              <span domPropsInnerHTML={this.header} />
            </a>
          </div>
          {this.subheader.length > 0 && (
            <p class={styles.text}>
              <a
                class={styles.textLink}
                href={this.url}
                title={this.header}
                onClick={(event: Event) => {
                  this.$emit('linkClicked', event);
                }}
              >
                <span domPropsInnerHTML={this.subheader} />
              </a>
            </p>
          )}
          <div class={styles.stats}>{this.$slots.stats}</div>
          {this.hasCommercialLabel && <AdvLabel class={styles.label} />}
        </article>
      );
    }
  });
