<template>
  <sticky-base-block ref="stickyBlock" :title="title" :point-sizes="currentPointSizes">
    <ul :class="$style['keypoints']">
      <li
        v-for="keypoint of keypoints"
        :key="keypoint.id"
        ref="posts"
        :class="[$style['item'], $style[keypoint.id === currentPointId ? 'active' : '']]"
      >
        <jtn-ui-typography
          :class="$style['link']"
          tag="a"
          link-type="link-dark-type-2"
          @click.prevent="setHash(keypoint.id)"
        >
          <jtn-ui-typography
            :class="$style['date']"
            tag="span"
            type="font-vijet-type-2"
          >
            {{ keypoint.date }}
          </jtn-ui-typography>
          <jtn-ui-typography tag="div" v-html="keypoint.keylabel"></jtn-ui-typography>
        </jtn-ui-typography>
      </li>
    </ul>
  </sticky-base-block>
</template>

<script>
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

import { JtnUiTypography } from '@jtnews/jtn-ui';

import StickyBaseBlock from '../../../../shared/components/sticky-base-block/index';

import { StoreMixin } from '../../../../shared/mixins';

export default {
  name: 'Keypoints',
  components: {
    JtnUiTypography,
    StickyBaseBlock
  },
  mixins: [StoreMixin],
  props: {
    keypoints: {
      type: Array,
      default() {
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      destroy: new Subject(),
      currentPointId: 0
    };
  },
  computed: {
    currentPointSizes() {
      let sizes = {};
      const index = this.currentPointId - 1;
      const currentPoint = index >= 0 ? this.$refs.posts[this.currentPointId - 1] : null;
      if (currentPoint) {
        sizes = {
          top: currentPoint.offsetTop,
          height: currentPoint.clientHeight
        };
      }

      return sizes;
    }
  },
  mounted() {
    this.store.layoutModule.currentPointId$
      .pipe(
        takeUntil(this.destroy),
        filter(state => state.id !== this.currentPointId)
      )
      .subscribe(state => {
        this.$route.meta.notScroll = state.navigatedBy === 'scroll';
        const hash = state.id === 0 ? '' : `post_${state.id}`;
        this.$router.push({ hash, query: this.$route.query }).catch(() => {
          // do nothing
        });
        this.currentPointId = state.id;
      });
  },
  beforeDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    setHash(id) {
      this.store.layoutModule.setCurrentPointId({
        navigatedBy: 'click',
        id
      });
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.keypoints {
  @include ul-clear;
}

.item {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  display: block;

  .active & {
    color: $color-primary;
  }

  .active &,
  &:hover {
    .date:before {
      color: inherit;
    }
  }
}

.date {
  position: relative;
  display: block;

  margin-bottom: 7px;
  padding-left: 13px;

  color: inherit;

  &:before {
    content: '•';
    position: absolute;
    top: 0;
    left: 0;

    border-radius: 50%;

    color: $color-surface-500;

    font-size: 28px;
  }

  .active & {
    color: $color-primary;
  }
}
</style>
