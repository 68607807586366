<template>
  <div id="left_column" :class="$style['left-column']">
    <div
      v-if="(hasKeypoints || hasCards) && !isIE"
      :class="$style['sticky-left-column']"
      :style="{ height: `${wrapHeight}px` }"
    >
      <broadcast-lead-author
        v-if="broadcastLeadAuthors && broadcastLeadAuthors.data"
        :data="broadcastLeadAuthors"
      />
      <div :class="[$style['sticky-block'], $style[scrollDirection]]">
        <LazyHydrate v-if="hasKeypoints" on-interaction="click">
          <keypoints :keypoints="keypointsData" :title="title" />
        </LazyHydrate>

        <cards-nav
          v-if="hasCards"
          :navs="cardsNav.data.cards"
          :title="cardsNav.data.header"
        />

        <div :class="$style['adv-wrap']" :style="{ height: `${advBlockHeight}px` }">
          <component
            v-for="blockItem of advData"
            v-bind="bindAdvComponent"
            :key="advKey(blockItem)"
            :adv="blockItem"
          />
        </div>
      </div>
    </div>
    <template v-else v-for="(blockItem, index) of leftColumnBlocks">
      <broadcast-lead-author
        v-if="blockItem.type === 'broadcast_lead_authors' && blockItem.data"
        :key="blockItem.type + index"
        :data="blockItem"
      />

      <LazyHydrate
        v-if="isIE && blockItem.type === 'broadcast_keypoints' && hasKeypoints"
        :key="blockItem.type + index"
        on-interaction="click"
      >
        <keypoints :keypoints="keypointsData" :title="title" />
      </LazyHydrate>

      <cards-nav
        v-if="isIE && blockItem.type === 'cards_keypoints' && hasCards"
        :key="blockItem.type + index"
        :navs="cardsNav.data.cards"
        :title="cardsNav.data.header"
      />

      <news-feed-block
        v-if="blockItem.type === 'news'"
        :key="blockItem.type + index"
        :data="blockItem"
        :class="$style.newsFeedBlock"
      />

      <template v-if="blockItem.type === 'adv' || blockItem.type === 'text_link_advert'">
        <AdvTextLinks
          v-if="isTextLink(blockItem)"
          :advData="blockItem.data"
        />

        <component
          v-else
          v-bind="bindAdvComponent"
          :key="advKey(blockItem)"
          :adv="blockItem"
          :class="[$style.newDesignAdv, $style[blockItem.type]]"
        />
      </template>
    </template>
  </div>
</template>
<script>
import { fromEvent, Subject } from 'rxjs';
import { throttleTime, takeUntil } from 'rxjs/operators';

import { StoreMixin } from '../../../shared/mixins';

import { LazyHydrate } from '@jtnews/shared';

import Keypoints from '../../../features/record/containers/keypoints';
import { Adv, AdvCreative, AdvTextLinks } from '../../../shared/components/_adv';
import { NewsFeedBlock } from '../../../shared/components/news-feed-block';
import CardsNav from '../../../features/record/containers/cards-nav';
import BroadcastLeadAuthor from '../../../features/record/components/broadcast-lead-authors';

export default {
  name: 'LeftColumn',
  components: {
    BroadcastLeadAuthor,
    Adv,
    AdvCreative,
    AdvTextLinks,
    NewsFeedBlock,
    Keypoints,
    CardsNav,
    LazyHydrate
  },
  mixins: [StoreMixin],
  data() {
    return {
      destroy: new Subject(),
      wrapHeight: 0,
      isSticky: false,
      oldScrollPosition: 0,
      newScrollPosition: 0,
      scrollDirection: 'down'
    };
  },
  computed: {
    isIE() {
      return this.store.deviceInfo.isIE;
    },
    dataFetching() {
      return this.store.dataFetching;
    },
    leftColumnBlocks() {
      return this.dataFetching ? [] : this.store.layoutModule.leftColumn;
    },
    keypoints() {
      return this.store.layoutModule.keypoints || {};
    },
    keypointsData() {
      return this.keypoints.data || [];
    },
    title() {
      return this.keypoints.params ? this.keypoints.params.title : '';
    },
    hasKeypoints() {
      return this.keypointsData.length > 0;
    },
    // TODO: вынести в стор после того как его структурируем
    hasCards() {
      return this.cardsNav && this.cardsNav.data && this.cardsNav.data.cards.length > 0;
    },
    broadcastLeadAuthors() {
      return this.leftColumnBlocks.find(block => block.type === 'broadcast_lead_authors');
    },
    cardsNav() {
      return this.leftColumnBlocks.find(block => block.type === 'cards_keypoints');
    },
    advData() {
      return this.leftColumnBlocks.filter(block => block.type === 'adv');
    },
    firstNewsFeedIndex() {
      return this.leftColumnBlocks.findIndex(block => block.type === 'news');
    },
    advBlockHeight() {
      if (this.$el) {
        const elHeight = this.$el.getBoundingClientRect().height;
        return elHeight - this.wrapHeight;
      }

      return 0;
    },
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    bindAdvComponent() {
      return {
        is: this.isScroogeTest ? 'AdvCreative' : 'Adv'
      };
    }
  },
  mounted() {
    this.store.layoutModule.recordHeight$
      .pipe(takeUntil(this.destroy))
      .subscribe(height => {
        this.wrapHeight = height;
      });

    fromEvent(window, 'scroll')
      .pipe(throttleTime(500), takeUntil(this.destroy))
      .subscribe(() => {
        this.onScroll();
      });
  },
  beforeDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    advKey(item) {
      return item.settings.placeId;
    },
    onScroll() {
      this.newScrollPosition = window.pageYOffset;
      this.scrollDirection =
        this.newScrollPosition > this.oldScrollPosition ? 'down' : 'up';
      this.oldScrollPosition = this.newScrollPosition;
    },
    isTextLink (blockItem) {
      return blockItem.type === 'text_link_advert' && blockItem?.data?.placement !== 'vb-left-1';
    },
  }
};
</script>

<style lang="scss" module>
@import 'styles';

$indent: 16px;

.left-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.sticky-left-column {
  position: relative;

  padding: $side-column-padding;
}

.sticky-block {
  position: sticky;
  top: 0;
  transition: top 0.3s;

  &.up {
    top: 56px;
  }
}

.adv-wrap {
  position: absolute;
  top: 100%;
  margin-top: $desktop-padding;
}

.newsFeedBlock {
  &:not(:last-of-type) {
    margin-bottom: -#{$indent};
  }
}
</style>
