<template>
  <sticky-base-block
    v-observe-visibility="obsHalfOptions"
    ref="stickyBlock"
    :title="title"
    :hash="'#record-header'"
    :point-sizes="currentPointSizes"
  >
    <ul :class="$style['nav-list']">
      <li
        v-for="(nav, index) of navs"
        :key="nav.id"
        ref="cards"
        :class="[
          $style['item'],
          $style[nav.id === currentPointId ? 'active' : '']
        ]"
      >
        <jtn-ui-typography
          :class="$style['number']"
          :tag="'span'"
          :type="'font-forum-type-1'"
        >
          {{ index + 1 }}.
        </jtn-ui-typography>
        <jtn-ui-typography
          :class="$style['link']"
          :tag="'a'"
          :type="'font-vijet-type-1'"
          :link-type="'link-dark-type-2'"
          @click.prevent="setHash(nav.id)"
        >
          <span v-html="nav.header"></span>
        </jtn-ui-typography>
      </li>
    </ul>
  </sticky-base-block>
</template>

<script>
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { JtnUiTypography } from '@jtnews/jtn-ui';

import { StoreMixin } from '../../../../shared/mixins';


import StickyBaseBlock from '../../../../shared/components/sticky-base-block/index';

export default {
  name: 'CardsNav',
  components: {
    JtnUiTypography,
    StickyBaseBlock
  },
  mixins: [StoreMixin],
  props: {
    navs: {
      type: Array,
      default() {
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      destroy: new Subject(),
      currentPointId: 0,
      obsHalfOptions: {
        callback: this.onVisibleBlock,
        once: true,
        intersection: {
          threshold: 0.5,
        },
      },
    };
  },
  computed: {
    currentPointSizes() {
      let sizes = {};
      const index = this.currentPointId - 1;
      const currentPoint = index >= 0 ? this.$refs.cards[this.currentPointId - 1] : null;
      if (currentPoint) {
        sizes = {
          top: currentPoint.offsetTop,
          height: currentPoint.clientHeight
        };
      }

      return sizes;
    }
  },
  mounted() {
    this.store.layoutModule.currentPointId$
      .pipe(
        takeUntil(this.destroy),
        filter((state) => state.id !== this.currentPointId)
      )
      .subscribe(state => {
        this.$route.meta.notScroll = state.navigatedBy === 'scroll';
        const hash = state.id === 0 ? '' : `card_${state.id}`;
        this.$router.push({ hash, query: this.$route.query }).catch(() => {
          // do nothing
        });
        this.currentPointId = state.id;
      });
  },
  beforeDestroy() {
    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    onVisibleBlock(val) {
      if (val) {
        this.sendNewReachGoal('Просмотр', 'viewCardsBlock');
      }
    },
    setHash(id) {
      this.store.layoutModule.setCurrentPointId({
        navigatedBy: 'click',
        id
      });

      this.sendNewReachGoal('Переход по якорной ссылке', 'clickCardsBlock');
    },
    sendNewReachGoal(valueName, goalName) {
      const blockType = 'Левая колонка';
      const fieldType = 'Карточки на материале';
      this.store.analyticsModule.sendNewReachGoal({
        blockType, fieldType, valueName, goalName
      });
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.nav-list {
  @include ul-clear;
}

.item {
  display: flex;

  margin-bottom: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid $color-surface-100;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    border-bottom: none;
  }
}

.number {
  min-width: 26px;
}

.link {
  display: block;

  .active & {
    color: $color-primary;
  }

  .active &,
  &:hover {
    .date:before {
      color: inherit;
    }
  }
}

</style>
