/**
 * @deprecated По причине полного редизайна. Новый компонент находится по пути /Users/wildflame/Documents/Code/SMH/jtnews/packages/jtn-ui/src/components/jtn-ui-base-block/jtn-ui-base-block.component.tsx
 */

import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';
import { JtnUiBtn } from '../../jtn-ui-btn';
import { JtnUiSideBlockHeader } from '../../jtn-ui-side-block-header';

import styles from './jtn-ui-base-block.styles.scss?module';

type BaseBlockButton = {
  type: string;
  text: string;
  url: string;
  align?: string;
  target: string;
  disabled: boolean;
  dataTest?: string;
};

interface Events {
  onHeaderClicked: Event;
  onClickPrimaryBtn: Event;
  onClickSecondaryBtn: Event;
}

const ICON_SIZE = 22;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiBaseBlock',
    props: {
      header: {
        type: String,
        default: ''
      },
      subTitle: {
        type: String,
        default: ''
      },
      hasBorder: {
        type: Boolean,
        default: true
      },
      url: {
        type: String,
        default: ''
      },
      targetUrl: {
        type: String,
        default: ''
      },
      hasIcon: {
        type: Boolean,
        default: true
      },
      contentItemsCount: {
        type: Number,
        default: 0
      },
      primaryButton: {
        type: Object as () => BaseBlockButton | null,
        default: null
      },
      secondaryButton: {
        type: Object as () => BaseBlockButton | null,
        default: null
      },
      testTheme: {
        type: String as () => '' | 'darkTheme' | 'blueTheme' | 'borderTheme',
        default: ''
      },
      counterLabel: {
        type: String,
        default: ''
      },
      isRedesign: {
        type: Boolean,
        default: false
      },
      dataTestHeader: {
        type: String,
        default: ''
      }
    },
    computed: {
      hasLogo(): boolean {
        return !!this.$slots.logo;
      },
      classContent(): string {
        return this.contentItemsCount % 2 !== 0 ? 'odd' : 'even';
      },
      linkType(): string {
        return this.testTheme === 'darkTheme' || this.testTheme === 'blueTheme'
          ? 'white'
          : 'blue';
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };

      return (
        <section
          class={[
            styles.baseBlock,
            styles[this.classContent],
            styles[this.testTheme],
            this.isRedesign ? styles.redesign : ''
          ]}
          {...attributes}
        >
          {this.header !== '' && (
            <JtnUiSideBlockHeader
              class={[styles.header, styles[this.hasBorder ? '' : 'has-border']]}
              hasBorder={this.hasBorder}
              text={this.header}
              subtitle={this.subTitle}
              counterLabel={this.counterLabel}
              hasLogo={this.hasLogo}
              url={this.url}
              linkType={this.linkType}
              isRedesign={this.isRedesign}
              dataTestHeader={this.dataTestHeader}
              onClick={(event: Event) => this.$emit('headerClicked', event)}
            >
              <template slot="logo">{this.$slots.logo}</template>

              {this.hasIcon ? (
                <template slot="icon">
                  <svg
                    stroke={this.testTheme === 'blueTheme' ? '#ffffff' : '#8d96a1'}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                  >
                    <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-arrow-right"></use>
                  </svg>
                </template>
              ) : (
                <template slot="remark">{this.$slots.remark}</template>
              )}
            </JtnUiSideBlockHeader>
          )}

          <div class={styles.content} data-jtn-class="content">
            {this.$slots.content}
          </div>

          {guardUnspecified(this.primaryButton) && (
            <div
              class={[styles.actions, styles[this.primaryButton.align || '']]}
              data-jtn-class="actions"
            >
              <JtnUiBtn
                href={this.primaryButton.url}
                target={this.primaryButton.target}
                isDisabled={this.primaryButton.disabled}
                dataAttrs={
                  this.primaryButton?.dataTest
                    ? [['data-test', this.primaryButton.dataTest]]
                    : []
                }
                onClick={(event: Event) => this.$emit('clickPrimaryBtn', event)}
              >
                {this.primaryButton.text}
              </JtnUiBtn>
              {guardUnspecified(this.secondaryButton) && (
                <JtnUiBtn
                  href={this.secondaryButton.url}
                  target={this.secondaryButton.target}
                  isDisabled={this.secondaryButton.disabled}
                  onClick={(event: Event) => this.$emit('clickSecondaryBtn', event)}
                >
                  {this.secondaryButton.text}
                </JtnUiBtn>
              )}
            </div>
          )}
        </section>
      );
    }
  });
